<template>
	<div>
		<v-navigation-drawer
			class="productUnitDrawer"
			v-model="drawer"
			app
			stateless
			right
			temporary
			width="480px"
		>
			<v-card class="" tile elevation="0">
				<v-card-title class="flex py-3 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">{{ title }}</div>
					<v-spacer></v-spacer>
					<v-btn
						tile
						depresed
						color="white--text"
						class="blue darken-4 mr-2"
						:disabled="!softwareTypeFormValidity"
						v-on:click="addSetting()"
					>
						Save
					</v-btn>
					<v-btn tile depresed class="darken-4" v-on:click="$emit('close', true)"> Close </v-btn>
				</v-card-title>
				<div class="form-grp-title mb-1">
					<div>{{ title }}</div>
					<small>
						<v-icon size="16">mdi-progress-question</v-icon> Only unique software types will be saved
					</small>
				</div>
				<v-card-text class="px-4 pt-1">
					<v-form v-model="softwareTypeFormValidity">
						<v-row>
							<v-col
								md="12"
								cols="12"
								class="my-auto py-0 pr-12 mb-1 position-relative light-blue-bg service-provider border rounded mt-2"
								v-for="(data, index) in create_software"
								:key="'Software_type_' + index + '_' + 'KK'"
							>
								<!-- data.name + '_' + data.value + '_' + index -->
								<!-- <v-radio-group hide-details dense v-model="create_software[index].provider_type">
								<v-radio
									v-for="p in provider_types"
									:key="p.title + p.value"
									:label="p.title"
									:value="p.value"
								>
									<template #label> </template>
								</v-radio>
							</v-radio-group>
							<SelectInput
								v-if="create_software[index].provider_type == 'company'"
								hide-details
								item-text="title"
								item-value="value"
								placeholder="Services"
								:items="services_array"
							></SelectInput> -->
								<!-- <v-text-field
								style="margin-bottom: 8px !important"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="create_software[index].name"
								placeholder="Software Type"
								@input="mod_name(index)"
							>
							</v-text-field> -->
								<TextInput
									style="margin-bottom: 8px !important"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="create_software[index].name"
									placeholder="Software Type"
									@input="mod_name(index)"
									:rules="[vrules.required(create_software[index].name, 'Software Type')]"
									:customClass="{ required: !create_software[index].name }"
								>
								</TextInput>

								<template v-if="data.id">
									<v-icon
										class="red--text cursor-pointer"
										style="position: absolute; top: 14px; right: 14px"
										:disabled="create_software.length == 1"
										v-on:click="deletefield(index)"
										>mdi-delete
									</v-icon>
								</template>
								<template v-else>
									<v-icon
										class="red--text cursor-pointer"
										style="position: absolute; top: 14px; right: 14px"
										v-on:click="deletefield(index)"
										:disabled="create_software.length == 1"
										>mdi-delete
									</v-icon>
								</template>
							</v-col>
							<v-col>
								<v-btn tile depressed color="blue darken-4" class="white--text" v-on:click="addField">
									<v-icon>mdi-plus</v-icon>
									Add
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { ContractEventBus } from "@/core/lib/contract.lib";
import TextInput from "@/view/components/TextInput";
// import SelectInput from "@/view/components/SelectInput";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "ManageAutocomplete",
	props: {
		title: {
			type: String,
			default: null,
		},
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pageLoading: false,
			create_software: [
				{
					name: null,
					value: null,
				},
			],
			softwareTypeFormValidity: true,
		};
	},
	methods: {
		mod_name(index) {
			let name = this.create_software[index].name;
			// let value = name.split(" ").join("_").toLowerCase();
			this.create_software[index].value = name;
		},
		addField() {
			this.create_software.push({
				name: null,
				value: null,
			});
		},
		deletefield(index) {
			this.create_software.splice(index, 1);
		},
		addSetting() {
			this.pageLoading = true;

			const unique_array_payload = this.create_software.reduce((uniq, curr_val) => {
				if (!uniq.some((item) => item.value == curr_val.value)) {
					uniq.push(curr_val);
				}
				return uniq;
			}, []);

			this.$store
				.dispatch(POST, {
					url: "software-settings",
					data: {
						software_settings: unique_array_payload,
					},
				})
				.then(() => {
					this.$emit("success");
					this.$emit("close");
					// this.$emit("close");
					// this.getSetting();
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Setting updated successfully.",
						},
					]);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSetting(occ = "") {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `software-settings/list`,
				})
				.then((data) => {
					if (data) {
						this.create_software = data;
					}
					if (occ != "mounted") {
						this.$emit("success", data);
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		deleteSetting(id) {
			this.pageLoading = true;
			this.$store
				.dispatch(DELETE, {
					url: `software-settings/delete/${id}`,
				})
				.then(() => {
					this.$emit("success");
					ContractEventBus.$emit("settings-refresh");
					this.$emit("deleted");
					this.getSetting();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		TextInput,
		// SelectInput,
	},
	mounted() {
		this.getSetting("mounted");
	},
};
</script>
